.waiting-warpper {
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 100%;
  width: 100%;
}

.info-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.order-info-title {
  font-family: SpaceGrotesk, SpaceGrotesk-SemiBold;
  font-weight: SemiBold;
  text-align: center;
  color: #0d1921;
}

.estimated-time-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.estimated-time-top {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.estimated-time-title {
  font-family: SpaceGrotesk, SpaceGrotesk-SemiBold;
  font-weight: SemiBold;
  text-align: center;
  color: #0d1921;
}

.estimated-time-value {
  font-family: SpaceGrotesk, SpaceGrotesk-Bold;
  text-align: left;
  color: #0d1921;
}

.estimated-time-unit {
  font-family: SpaceGrotesk, SpaceGrotesk-Regular;
  text-align: center;
  color: #0d1921;
}

@media only screen and (max-width: 768px) {
  .order-info-title {
    font-size: 18px;
    line-height: 23px;
    padding-top: 30px;
  }

  .estimated-time-wrapper {
    padding-top: 115px;
  }

  .estimated-time-icon {
    width: 20px;
    height: 20px;
  }

  .estimated-time-title {
    font-size: 18px;
    line-height: 23px;
    padding-left: 6px;
  }

  .estimated-time-info {
    padding-top: 14px;
  }

  .estimated-time-value {
    font-size: 36px;
    font-weight: 700;
    line-height: 46px;
  }

  .estimated-time-unit {
    font-size: 18px;
    font-weight: 400;
    line-height: 23px;
    padding-left: 6px;
  }
}

@media (min-width: 768px) {
  .order-info-title {
    font-size: 28px;
    padding-top: 34px;
  }

  .estimated-time-wrapper {
    padding-top: 120px;
  }

  .estimated-time-icon {
    width: 28px;
    height: 28px;
  }

  .estimated-time-title {
    font-size: 28px;
    line-height: 36px;
    padding-left: 10px;
  }

  .estimated-time-info {
    padding-top: 24px;
  }

  .estimated-time-value {
    font-size: 64px;
    font-weight: 700;
    line-height: 82px;
  }

  .estimated-time-unit {
    font-size: 28px;
    font-weight: 400;
    line-height: 36px;
    padding-left: 6px;
  }
}
